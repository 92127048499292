import React, { useState } from "react";
import { Col, Row } from "react-bootstrap";
import Membership from "./Membership";
import Placeholder from "./Placeholder";
import "./memberships.css"; // Import the CSS file for styling

// Carousel component definition
export default function Carousel({ slides, title }) {
  // useState hook to manage the current active slide
  const [slide, setSlide] = useState(0);

  // Function to handle going to the next slide
  const handleNext = () => {
    // Update the slide state to the next slide, wrap around when reaching the end
    setSlide((prevSlide) => (prevSlide + 1) % slides.length);
  };

  // Function to handle going to the previous slide
  const handlePrev = () => {
    // Update the slide state to the previous slide, wrap around when reaching the beginning
    setSlide((prevSlide) => (prevSlide - 1 + slides.length) % slides.length);
  };

  return (
    <div className="carousel-container">
      <h3>{title}</h3>
      <div className="carousel slide">
        <div className="carousel-inner">
          {slides.map((group, index) => (
            <Col
              key={index}
              // Conditionally apply the 'active' class to the currently active slide
              className={`carousel-item ${index === slide ? "active" : ""}`}
            >
              <Row className="d-flex justify-content-center">
                {/* Iterate through each item in the group */}
                {group.map((item, itemIndex) =>
                  // Render a placeholder if the item is of type 'placeholder'
                  item.type === "placeholder" ? (
                    <Col key={itemIndex} lg={3}>
                      <Placeholder />
                    </Col>
                  ) : (
                    // Render a Membership component for real membership data
                    <Col key={itemIndex} lg={3}>
                      <Membership details={item} />
                    </Col>
                  )
                )}
              </Row>
            </Col>
          ))}
        </div>
        {slides.length === 1 ? (
          ""
        ) : (
          <>
            <button
              className="carousel-control-prev"
              type="button"
              onClick={handlePrev}
            >
              <span
                className="carousel-control-prev-icon"
                aria-hidden="true"
              ></span>
              <span className="visually-hidden">Previous</span>
            </button>
            <button
              className="carousel-control-next"
              type="button"
              onClick={handleNext}
            >
              <span
                className="carousel-control-next-icon"
                aria-hidden="true"
              ></span>
              <span className="visually-hidden">Next</span>
            </button>
          </>
        )}
      </div>
    </div>
  );
}
