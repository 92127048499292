import React from "react";
import Headers from "./Headers";
import { Container} from "react-bootstrap";
import Coach from "./Coach";

export default function Coaches() {
  return (
    <div className="coaches" id="home">
      <Headers title="Coaches" />
      <div className="coaches-body">
        <Container className="d-flex justify-content-center">
          <Coach/>
        </Container>
      </div>
    </div>
  );
}
