import mick from "../assets/mick-punch-3.png";
import mauricio from "../assets/Mauricio_Yin_Viera_profile_pic.png"

export const details = [
  {
    name: "Mickael",
    img: mick,
    desc: "Mickael, a skilled and patient coach, founded Gloves Up Boxing in 2016. He creates a supportive environment where technique and personal growth are paramount.",
    insta: "https://www.instagram.com/gloves_up_boxing/",
  },
  // {
  //   name: "Fawaz",
  //   img: mick,
  //   desc: "25 word count - Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin euismod placerat odio, id commodo nulla dapibus ut. Quisque molestie ex sed nunc.",
  //   insta: "",
  // },
  {
    name: "Mauricio Yin Viera",
    img: mauricio,
    desc: "Passionate about technique, Mauricio is calm and attentive to detail. Practicing Boxing, Muay Thai, Kickboxing, Jiu-Jitsu (brown belt), and Wrestling, you will always learn something in his classes.",
    insta: "https://www.instagram.com/myv_china/?igsh=dzFhbnRzdXFiZHB3",
  }
];