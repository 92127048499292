import React from "react";
import Headers from "./Headers";
import { Container, Row, Col } from "react-bootstrap";

export default function NotFound() {
function onClick (){
  window.location.replace("https://www.glovesupboxing.ca");
}

  return (
    <div className="not-found" id="home">
      <Headers title="Page Not Found" />
      <div className="not-found-body">
        <Container>
          <Row className="not-found-msg">
            <Col className="d-flex justify-content-center">
              Sorry this page does not exist <br /> please return home using the
              button below<br /> Thank you!
            </Col>
          </Row>
          <Row>
            <Col xs={1}></Col>
            <Col className="d-flex justify-content-center">
              <a className="btn btn-secondary" onClick={() => onClick()}>Go to Homepage</a>
            </Col>
            <Col xs={1}></Col>
          </Row>
        </Container>
      </div>
    </div>
  );
}
