import React from "react";
import logo from "../assets/gloves-up-logo.png";
import email from "../assets/svgs/email.svg";
import insta from "../assets/svgs/instagram2.svg";

export default function Footer() {
  const logos = [
    {
      title: "Email",
      img: email,
      link: "glovesupboxingteam@gmail.com",
    },
    {
      title: "Instagram",
      img: insta,
      link: "https://www.instagram.com/gloves_up_boxing/",
    },
  ];

  function onClick(input) {
    if (input === "glovesupboxingteam@gmail.com") {
      window.location.href =
        "mailto:glovesupboxingteam@gmail.com?subject=Reaching%20Out";
    } else {
      window.open(input, "_blank");
    }
  }

  return (
    <div className="footer">
      <div className="content">
        <div className="row-1">
          <div className="d-flex justify-content-center">
            <img src={logo} style={{ height: "50px" }} />
            <div className="row-1-address">
              801 St Clarens Ave Unit 3, <br />
              Toronto, M6H 3X4
              <br />
              (647) 639-3811
            </div>
          </div>
          <div className="symbols">
            {logos.map((logo, index) => (
              <img
                key={index}
                src={logo.img}
                onClick={() => onClick(logo.link)}
              />
            ))}
          </div>
        </div>
        <div className="row-2">
          <div>Copyright © 2024. All rights are reserved</div>
        </div>
      </div>
    </div>
  );
}
