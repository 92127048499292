import React from "react";

export default function Headers({ title, small }) {
  // Default title if 'title' prop is not passed
  const defaultTitle = "Default Title";

  // set variables here
  const primaryColor = "148, 19, 19";
  // const secondaryColor = "255, 255, 255";

  // opacity updated
  const pO = `rgb(${primaryColor}, .8), rgb(${primaryColor}, .7), rgb(${primaryColor}, .6), rgb(${primaryColor}, .5), rgb(${primaryColor}, .3)`;
  // const sO = `rgb(${secondaryColor}, .3)`;

  let top;
  if (small != undefined) {
    top = "50px";
  } else {
    top = "200px";
  }

  // styles passed to html
  const headerStyle = {
    padding: `${top} 0px 10px 0px`,
    background: `linear-gradient(to top, ${pO})`,
  };

  return (
    <div className="headers" style={headerStyle}>
      <div className="title">{title || defaultTitle}</div>
    </div>
  );
}
