import React from "react";
import { Row, Col } from "react-bootstrap";

export default function MerchCard({ merchBody }) {
  const handleClick = (input) => {
    console.log(input);
  };
  return (
    <Col className="merch-card" onClick={() => handleClick(merchBody)}>
      <Row className="merch-card-img">
        <img src={merchBody.img} />
      </Row>
      <Row className="merch-card-body">
        <div className="merch-card-body-name">{merchBody.name}</div>
        <div className="merch-card-body-price">{merchBody.price}</div>
        <Col>
          <button className="btn btn-secondary merch-card-buy-btn">
            Buy Now
          </button>
        </Col>
      </Row>
    </Col>
  );
}
