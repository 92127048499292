import React from "react";
import { Row, Col } from "react-bootstrap";
import insta from "../assets/svgs/instagram.svg";
import { details } from "../../src/data/coachesDetails";

export default function Coach() {
  function onClick(item) {
    if (item === "Mauricio Yin Viera") {
      window.open("https://glovesup.as.me/?appointmentType=45244944");
      console.log(item)
    } else if (item === "Mickael") {
      window.open("https://glovesup.as.me/?appointmentType=35680597");
    }
  }
  return (
    <Row className="coach-row">
      {details.map((item) => (
        <Col
          xxl={6}
          xl={6}
          lg={12}
          className="d-flex justify-content-center coach-parent"
        >
          <div className="card coach">
            <Col md={6} className="d-flex justify-content-center">
              <img
                src={item.img}
                className="card-img-top coach-img"
                alt="..."
              />
            </Col>
            <Col md={6}>
              <div className="card-body coach-body">
                <h5 clasNameclassNames="card-title coach-title">{item.name}</h5>
                <p className="card-text coach-text">{item.desc}</p>
                <div className="coach-contact">
                  <div className="coach-insta">
                    <img src={insta} className="coach-insta-img" />
                    <a href={item.insta}>Instagram</a>
                  </div>
                  <a className="btn btn-primary" onClick={() => onClick(item.name)}>
                    Book a Session
                  </a>
                </div>
              </div>
            </Col>
          </div>
        </Col>
      ))}
    </Row>
  );
}
