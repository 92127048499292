import React from "react";
import { Container } from "react-bootstrap";
import Headers from "./Headers";

export default function Classes() {

  return (
    <div className="classes">
      <Headers title="Book A Class" small="yes"/>
      <div className="classes-body d-flex justify-content-center">
        <Container className="d-flex justify-content-center">
          <iframe
            src="https://app.acuityscheduling.com/schedule.php?owner=26831882&ref=embedded_csp"
            title="Schedule Appointment"
            width="100%"
            height="600px"
            frameBorder="0"
            style={{borderRadius: "2px", backgroundColor: "white"}}
          ></iframe>
          <script
            src="https://embed.acuityscheduling.com/js/embed.js"
            type="text/javascript"
          ></script>
        </Container>
      </div>
    </div>
  );
}
