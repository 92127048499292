import React from "react";
import { Row, Col } from "react-bootstrap";

function PriceTag({ type, price }) {
  return (
    <div className="card-body-price">
      {type === "monthly"
        ? `$${price}/Monthly`
        : typeof price === "number"
        ? `$${price}`
        : `${price}`}
    </div>
  );
}

function MembershipPoints({ points }) {
  return (
    <ul className="list-group list-group-flush">
      {points.map((item, index) => (
        <div
          key={index}
          className="group-item membership-group-item"
          style={{ color: item.color }}
        >
          <img src={item.symbol} alt="point-symbol" />
          {item.text}
        </div>
      ))}
    </ul>
  );
}

function MembershipPoints2({ points }) {
  return (
    <>
      {points.map((item, index) => (
        <div key={index} className="group-item membership-group-desc">
          {item.text.split("\n").map((list, i) => (
            <div key={i}>{list}</div>
          ))}
        </div>
      ))}
    </>
  );
}

export default function Membership({ details }) {
  function onClick() {
    window.open(
      "https://app.acuityscheduling.com/catalog.php?owner=33961789&action=addCart&clear=1&id=1853162",
      "_blank"
    );
  }

  return (
    <div className="card membership">
      <Row className="membership-card-img">
        <img className="membership-img-top" alt="..." src={details.img} />
      </Row>
      <Row className="card-body memebership-card-body">
        <div className="card-body membership-card-body">
          <h5 className="card-title membership-card-title">{details.title}</h5>
          {details.type !== "monthly" ? (
            <MembershipPoints2 points={details.points} />
          ) : (
            <MembershipPoints points={details.points} />
          )}
          <PriceTag type={details.type} price={details.price} />
        </div>
        <Col className="membership-card-buy">
          <button
            className="btn btn-primary membership-card-buy-btn"
            onClick={onClick}
          >
            Buy Now
          </button>
        </Col>
      </Row>
    </div>
  );
}
