import React from "react";

export default function CookieAlert({ setCookies }) {
  const giveCookieConsent = () => {
    setCookies("cookieConsent", true, { path: "/" });
  };

  return (
    <>
      <div className="cookie-consent-banner">
        <div className="cookie-consent-div">
          <p class="cookie-message">
            This website uses online tracking technologies such as cookies to
            enhance site navigation and analyse site usage.
            <br />
            By continuing to visit this site, you agree to our use of cookies.
          </p>
          <button
            className="cookie-x-button"
            onClick={giveCookieConsent}
            style={{ alignSelf: "baseline" }}
          >
            x
          </button>
        </div>
        <button className="accept-cookie-btn" onClick={giveCookieConsent}>
          Accept Cookies
        </button>
      </div>
    </>
  );
}
