import React from "react";
import Headers from "./Headers";
import { Container, Row, Col } from "react-bootstrap";
import MerchCard from "./MerchCard";
import shirt1 from "../assets/test_shirt_1.png";
import shirt2 from "../assets/test_shirt_2.png";

export default function Merch() {
  const merchBody = [
    {
      img: shirt1,
      name: "BOXING SHIRT",
      price: "$25",
      desc: "black t-shirt",
      code: "123",
    },
    {
      img: shirt2,
      name: "BOXING SHIRT WHITE",
      price: "$30",
      desc: "white t-shirt",
      code: "321",
    },
  ];

  return (
    <div className="merch" id="home">
      <Headers title={"Merch"} />
      <div className="merch-body">
        <Container className="d-flex justify-content-center">
          <Row className="d-flex justify-content-center">
            {merchBody.map((obj) => (
              <Col key={obj.code} xxl={4} xl={4} lg={4} md={4} sm={6} xs={12}>
                <MerchCard merchBody={obj} />
              </Col>
            ))}
          </Row>
        </Container>
      </div>
    </div>
  );
}
