import React from 'react';
import { Container } from 'react-bootstrap';
import Headers from "./Headers";

export default function Pt() {
  return (
    <div className="pt">
      <Headers title="Private Training"/>
      <div className="pt-body d-flex justify-content-center">
        <Container className="d-flex justify-content-center">
        <iframe src="https://app.acuityscheduling.com/schedule.php?owner=26831882&appointmentType=35680597&ref=embedded_csp" title="Schedule Appointment" width="100%" height="800" frameBorder="0"></iframe><script src="https://embed.acuityscheduling.com/js/embed.js" type="text/javascript"></script>
        </Container>
      </div>
    </div>
  )
}
