import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import Headers from "./Headers";
import mick from "../assets/mick-punch-3.png";

export default function OurStory() {
  const input = [
    {
      value:
        "Gloves Up Boxing has finally found a roof! First created in 2016 by Mickael Alban, Gloves Up has been home to a dedicated group of boxers who bring their colour, voice and energy to the sport.",
    },
    {
      value:
        "We are a team and a community, both in and out of the ring, so you can always count on a friend to keep your Gloves Up at all times. This is a space to let out your emotions in a safe and supportive environment where we take care of ourselves both inside and out.",
    },
    {
      value:
        "If you are looking to learn boxing techniques, challenge yourself in new ways or just for a bit of fun, this could be your home! Thank you for making Gloves Up a reality. See you in the ring!",
    },
    {
      value: "In Boxing like in Life, Gloves Up!",
    },
  ];

  return (
    <div className="story" id="home">
      <Headers title="Our Story" />
      <div className="story-body">
        <Container className="d-flex justify-content-center">
          <Row lg={12} className="d-flex justify-content-center story-content-parent">
            <Col>
              {input.map((item) => (
                <div className="story-content">{item.value}</div>
              ))}
            </Col>
            <Col xl={6} lg={12} className="d-flex justify-content-center story-img-col">
              <img
                src={mick}
              />
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
}
