import React, { useState } from "react";
import { Row, Col } from "react-bootstrap";
import Membership from "./Membership";

export default function AccordionComponent({ details }) {
  const [show, setShow] = useState(0); // Track the index of the opened item

  return (
    <div className="accordion-container">
      <div className="accordion" id="accordionExample">
        {details.map((item, index) => (
          <div key={index} className="accordion-item">
            <h2 className="accordion-header">
              <button
                className={
                  show === index
                    ? "accordion-button"
                    : "accordion-button collapsed"
                }
                type="button"
                data-bs-toggle="collapse"
                data-bs-target={`#collapse${index}`}
                aria-expanded={show === index ? "true" : "false"}
                aria-controls={`collapse${index}`}
                onClick={() => setShow(show === index ? null : index)} 
              >
                {item.title}
              </button>
            </h2>
            <div
              id={`collapse${index}`}
              className={
                show === index
                  ? "accordion-collapse collapse show"
                  : "accordion-collapse collapse"
              }
              data-bs-parent="#accordionExample"
            >
              <div className="accordion-body">
                <Row>
                  {item.items.map((i, index) => (
                    <Col sm={12} className="accordion-body-item" key={index}>
                      <Membership details={i} />
                    </Col>
                  ))}
                </Row>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
